<template>
  <div class="selWrap">
    <!-- 吸顶查询 -->
    <van-sticky>
      <van-search
        v-model="form.applicantName"
        placeholder="投保人"
        @search="onSearch"
        @cancel="onCancel"
      />
      <!-- 下拉菜单 -->
      <van-dropdown-menu>
        <van-dropdown-item
          v-model="form.issueState"
          :options="optionIssueState"
          @change="change"
        />
        <van-dropdown-item
          v-model="form.orderState"
          :options="optionOrderState"
          @change="change"
        />
        <van-dropdown-item
          v-model="form.invoiceState"
          :options="optionInvoiceState"
          @change="change"
        />
      </van-dropdown-menu>
    </van-sticky>
    <!-- 列表 -->
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
      offset="300"
      :error.sync="error"
      error-text="请求失败，点击重新加载"
    >
      <ul v-for="item in list" :key="item.id" :title="item.name">
        <div class="card-container">
          <van-card :title="item._productAndCombo" :tag="item.companyName">
            <div slot="desc">{{ item.applicantName }}</div>
            <div slot="desc">{{ item.riskName }}</div>
            <template #tags>
              <van-tag color="#ecf5ff" text-color="#409eff">{{
                item.companyName
              }}</van-tag>
            </template>

            <!-- 投保人 -->
            <!--  价格插槽-->
            <div slot="price">
              <span class="price-value">{{ item.price }}</span>
            </div>
            <div slot="bottom">
              <span
                :class="{
                  // 虽然这样写不如if-else那样高性能，但方便一点
                  ' text-color-info':
                    item._stateStr === '待确认' || item._stateStr === '已退保',
                  ' text-color-primary': item._stateStr === '已确认',
                  ' text-color-success': item._stateStr === '已出单',
                  ' text-placeholder': item._stateStr === '失效/拒保'
                }"
                >{{ item._stateStr }}</span
              >
            </div>
            <div slot="bottom">{{ item.createTime }}</div>

            <!-- 投保按钮插槽 -->
            <div slot="footer">
              <!-- 详情 -->
              <van-button
                type="text"
                class="cz_button"
                plain
                size="mini"
                @click="onDetail(item.id)"
                >详情</van-button
              >
              <!-- 下载保单 -->
              <van-button
                type="text"
                class="cz_button"
                plain
                size="mini"
                v-if="item.payState === 2 && item.epolicyUrl !== null"
                @click="downloadEPolicy(item)"
                >下载保单</van-button
              >
              <!-- 删除 -->
              <van-button
                type="text"
                class="cz_button"
                plain
                size="mini"
                v-if="
                  (item.insureState !== 2 &&
                    item.payState !== 2 &&
                    item.underwritingState !== 2 &&
                    item.issueState !== 2) ||
                    item.withdrawState === 2 ||
                    item.cancelState === 2
                "
                @click="delOrder(item)"
                >删除</van-button
              >
              <!-- 在保清单 -->
              <van-button
                type="text"
                class="cz_button"
                plain
                size="mini"
                v-if="
                  (item.riskName === '团体意外险' ||
                    item.riskName === '雇主责任险') &&
                    item.payState === 2
                "
                @click="onUnderInsurance(item)"
                >在保清单
              </van-button>
            </div>
          </van-card>
        </div>
      </ul>
    </van-list>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { OrderState } from "@/utils/order-offline-state-compute";
import { Dialog } from "vant";
export default {
  data() {
    const issueStates = [
      { text: "出单类型", value: null },
      { text: "自动", value: 1 },
      { text: "手动", value: 2 },
      { text: "核保", value: 3 }
    ];
    const orderStates = [
      { text: "订单状态", value: null },
      { text: "待确认", value: 1 },
      { text: "已确认", value: 2 },
      { text: "已出单", value: 3 },
      { text: "失效/拒保", value: 4 },
      { text: "已退保", value: 5 }
    ];
    const invoiceStates = [
      { text: "开票状态", value: null },
      { text: "开票中", value: 1 },
      { text: "已开票", value: 2 },
      { text: "开票失败", value: 3 },
      { text: "开票取消", value: 4 }
    ];
    return {
      loading: false, // 是否处在加载状态
      finished: false, // 是否已加载完成
      error: false, // 是否加载失败
      loadEnd: false,
      list: [],
      form: {
        page: 1,
        pageSize: 10,
        type: this.$route.query.type,
        applicantName: null, //投保人
        issueState: null, // 出单状态
        orderState: null, //订单状态
        invoiceState: null //开票状态
      },
      optionIssueState: issueStates, //出单状态
      optionOrderState: orderStates, //订单状态
      optionInvoiceState: invoiceStates
    };
  },
  methods: {
    ...mapActions("order", ["pagination", "del", "underInsurance"]),
    computeOrderState({
      confirmState,
      issueState,
      cancelState,
      withdrawState,
      insureState,
      applyPolicyState
    }) {
      return new OrderState(
        confirmState,
        issueState,
        cancelState,
        withdrawState,
        insureState,
        applyPolicyState
      ).state;
    },
    async onLoad() {
      // 若加载条到了底部;
      let timer = setTimeout(() => {
        // 定时器仅针对本地数据渲染动画效果,项目中axios请求不需要定时器
        this.getList(); // 调用上面方法,请求数据
        this.form.page++; // 分页数加一
        this.finished && clearTimeout(timer); //清除计时器
      }, 100);
    },
    getList() {
      const _this = this;
      this.pagination({ ...this.form }).then(res => {
        const data = res.data.data;
        if (data.rows.length === 0) {
          // 判断获取数据条数若等于0
          this.list = []; // 清空数组
          this.finished = true; // 停止加载
        }
        // 若数据条数不等于0
        this.total = data.records; // 给数据条数赋值
        //调整数据格式
        const rows = data.rows.map(function(item) {
          // 拼接产品名和方案名
          item._productAndCombo = item.productName + " | " + item.comboName;
          //转换状态
          const state = _this.computeOrderState(item);
          if (state !== undefined) {
            item._stateStr = state.includes("已确认") ? "已确认" : state;
          }
          return item;
        });
        this.list.push(...rows); // 将数据放入list中
        this.loading = false; // 加载状态结束
        // 如果list长度大于等于总数据条数,数据全部加载完成
        if (this.list.length >= data.records) {
          this.finished = true; // 结束加载状态
        }
      });
    },
    onSearch() {
      this.form.page = 1;
      this.list = [];
      this.onLoad();
    },
    onCancel() {
      console.log("取消");
    },
    change() {
      this.form.page = 1;
      this.list = [];
      this.onLoad();
    },
    onDetail(id) {
      this.$router.push({
        name: "mobile-order-detail",
        params: {
          id: id
        }
      });
    },
    downloadEPolicy({ epolicyUrl }) {
      epolicyUrl && window.open(epolicyUrl);
    },
    delOrder({ id }) {
      Dialog.confirm({
        title: "删除确认弹窗",
        message: "确认删除订单号为" + id + "的数据？"
      })
        .then(() => {
          this.del(id).then(res => {
            if (res.data.code === 2000) {
              this.$message.success("删除成功");
              this.onSearch();
            }
          });
        })
        .catch(() => {});
    },
    onUnderInsurance({ id, extOrderNo }) {
      this.$axios
        .get(
          `${process.env.VUE_APP_BASE_API}/jbb/order/underInsurance?orderId=${id}`,
          {
            responseType: "blob" //定义响应类型
          }
        )
        .then(response => {
          //拿到文件blob
          let blob = response.data;
          //定义下载文件名
          var nowDate = new Date();
          var str =
            nowDate.getFullYear() +
            "" +
            (nowDate.getMonth() + 1) +
            nowDate.getDate();
          console.log("str", str);
          let fileName = "在保清单_" + extOrderNo + "_" + str + ".xlsx";
          // 判断IE保存方法还是其他浏览器保存方法
          if ("download" in document.createElement("a")) {
            //将blob传出获取文件下载地址
            let binaryData = [];
            binaryData.push(blob);
            var url = window.URL.createObjectURL(new Blob(binaryData));

            //定义一个a标签
            var a = document.createElement("a");

            a.style.display = "none";
            //将下载地址赋值给a标签的href
            a.href = url; //将下载文件的名字赋值给a标签的download
            a.download = fileName; //添加到body中
            document.body.appendChild(a);
            //调用a标签的点击方法
            a.click();
            setTimeout(() => {
              window.URL.revokeObjectURL(url); // 释放URL 对象
              document.body.removeChild(a); //移除a标签
            }, 1000); //解决部分浏览器下载时“无此文件”问题
          } else {
            navigator.msSaveBlob(blob, fileName);
          }
        });
    }
  }
};
</script>
<style lang="less" scoped>
.price-value {
  color: goldenrod;
}
.van-card {
  border: #e5e7eb solid 1px;
  border-radius: 0.375rem;
  margin-bottom: 0.5rem;
}
.selWrap /deep/ .van-dropdown-menu span {
  //less 语法的样式穿透
  font-size: 12px;
}
.selWrap /deep/ .van-dropdown-menu .van-dropdown-menu__bar {
  height: 38px;
}
.cz_button {
  color: #409eff;
}
.selWrap
  /deep/
  .van-list
  .card-container
  .van-card
  .van-card__header
  .van-card__content
  .van-card__title {
  font-size: 14px;
  font-weight: bold;
}
</style>
